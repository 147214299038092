@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  li {
    @apply p-4
  }

  button {
    @apply text-white border bg-blue-600 border-blue-600
    hover:bg-transparent hover:text-blue-600 rounded-md
  }
}